/**
 * BasePage
 */
/* global SITE_THEME, SSRContext, isJestTest */

import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { i18n } from 'next-i18next'
import classNames from 'classnames';
import _ from 'lodash';
import BaseContext from './BaseContext';
import Header from 'Components/Header';
import Footer from 'Components/Footer';
import ClipPaths from 'Components/ClipPaths';
import CookiePopup from 'Components/CookiePopup';
import WagtailUserbar from 'Components/WagtailUserbar';
import { useStyle } from 'Themes/theme';

const fallbackTheme = process?.env?.SITE_NAME || 'Medborgarskolan';

import sD from './BasePage.Dansskolanentre.module.scss';
import sK from './BasePage.Kulturama.module.scss';
import sM from './BasePage.Medborgarskolan.module.scss';
import sP from './BasePage.Pahlmans.module.scss';
import sT from './BasePage.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const BasePage = ({
    children = null,
    header = {},
    footer = {},
    cookiePopup = {},
    theme = '',
    language = 'sv',
    isPreview: preview = false,
    _class = '',
    meta = {},
    ldScripts = [],
    wagtailUserbar = null,
    containerName = '',
}) => {
    // For handling base context in storybook it is added to all stories, check if
    // storybook theme exists, otherwise it is regular site and context can be created
    const {currentTheme: storybookTheme} = useContext(BaseContext);

    const [currentTheme, setCurrentTheme] = useState(
        !_.isEmpty(storybookTheme) ? storybookTheme : (theme || fallbackTheme)
    );

    const [isSSR, setIsSSR] = useState(true);
    const [isPreview, setIsPreview] = useState(preview);
    const [lang, setLang] = useState(language);

    useEffect(() => {
        const themes = [
            'Medborgarskolan', 'Kulturama', 'Pahlmans', 'PahlmansGy',
            'Tillskararakademin', 'Dansskolanentre',
        ];
        const checkIsSSR = typeof(SSRContext) !== 'undefined';
        const checkIsPreview = checkIsSSR && SSRContext.IS_PREVIEW !== undefined ? SSRContext.IS_PREVIEW : isPreview;
        const ssrTheme = checkIsSSR && SSRContext.SITE_THEME !== undefined ? SSRContext.SITE_THEME : '';
        if(checkIsSSR && ssrTheme && themes.includes(ssrTheme)) {
            setCurrentTheme(ssrTheme);
        } else if(typeof SITE_THEME !== 'undefined' && SITE_THEME && themes.includes(SITE_THEME) && SITE_THEME !== currentTheme) {
            setCurrentTheme(SITE_THEME);
        } else if(!_.isEmpty(theme) && themes.includes(theme) && theme !== currentTheme) {
            setCurrentTheme(theme);
        }

        const ssrLang = checkIsSSR && SSRContext.LANGUAGE_CODE !== undefined ? SSRContext.LANGUAGE_CODE : language;
        if(ssrLang !== language) {
            setLang(ssrLang);
        }

        setIsSSR(checkIsSSR);
        setIsPreview(checkIsPreview);
    }, []);

    const s = useStyle(styles, currentTheme);

    const container = containerName || _class;
    const classes = classNames(
        s['BasePage'],
        [s[`BasePage--${container}`]],
        container,
    );

    const settings = {
        currentTheme: currentTheme,
        cookiePopup: cookiePopup,
        isSSR: isSSR,
        isPreview: isPreview,
        language: lang,
        containerName: container,
    };

    return (
        <>
            <Meta {...meta} ldScripts={ldScripts} />

            <BaseContext.Provider value={settings}>
                <div className={classes}>
                    {cookiePopup &&
                        <CookiePopup {...cookiePopup} />
                    }

                    <Header {...header} />

                    <div className={s['BasePage__Container']} role="document">
                        {children}
                    </div>

                    <div className={s['BasePage__Footer']}>
                        <Footer {...footer} />
                    </div>

                    <ClipPaths />
                </div>
            </BaseContext.Provider>

            {!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
        </>
    );
};

BasePage.propTypes = {
    children: PropTypes.node,
    header: PropTypes.object,
    footer: PropTypes.object,
    cookiePopup: PropTypes.object,
    meta: PropTypes.object,
    ldScripts: PropTypes.array,
    theme: PropTypes.string,
    language: PropTypes.string,
    isPreview: PropTypes.bool,
    wagtailUserbar: PropTypes.shape({
        html: PropTypes.string,
    }),
    _class: PropTypes.string,
    containerName: PropTypes.string,
};

const Meta = ({
    htmlTitle = '',
    description = '',
    ogTitle = '',
    ogDescription = '',
    ogUrl = '',
    ogImage = '',
    ogType = '',
    twitterSite = '',
    twitterUser = '',
    twitterTitle = '',
    twitterDescription = '',
    twitterUrl = '',
    twitterImage = '',
    robots = '',
    pageId = '',
    canonicalLink = '',
    translations = [],
    googleSiteVerification = '',
    facebookDomainVerification = '',
    rekTitle = '',
    rekDescription = '',
    rekImage = {},
    rekPubdate = '',
    rekModdate = '',
    rekEnddate = '',
    ldScripts = [],
}) => {
    return (
        <Head>
            <title>{htmlTitle}</title>

            {!!canonicalLink && (
                <link rel="canonical" href={canonicalLink} />
            )}

            {translations && translations.map(({ href, lang }, index) => (
                <link rel="alternate" href={href} hreflang={lang} />
            ))}

            {!!description && (
                <meta name="description" content={description} />
            )}
            {!!ogTitle && (
                <meta property="og:title" content={ogTitle} />
            )}
            {!!ogDescription && (
                <meta
                    property="og:description"
                    content={ogDescription}
                />
            )}
            {!!ogUrl && <meta property="og:url" content={ogUrl} />}
            {!!ogImage && (
                <meta property="og:image" content={ogImage} />
            )}
            {!!ogType && <meta property="og:type" content={ogType} />}
            {!!twitterSite && (
                <meta name="twitter:site" content={twitterSite} />
            )}
            {!!twitterUser && (
                <meta name="twitter:user" content={twitterUser} />
            )}
            {!!twitterTitle && (
                <>
                <meta name="twitter:card" content="summary" />
                <meta property="twitter:title" content={twitterTitle} />
                </>
            )}
            {!!twitterDescription && (
                <meta
                    property="twitter:description"
                    content={twitterDescription}
                />
            )}
            {!!twitterUrl && (
                <meta property="twitter:url" content={twitterUrl} />
            )}
            {!!twitterImage && (
                <meta property="twitter:image" content={twitterImage} />
            )}
            <meta name="robots" content={robots} />

            {!!pageId && (
                <meta name="page_ID" content={pageId} />
            )}

            {googleSiteVerification && (
                <meta name="google-site-verification" content={googleSiteVerification} />
            )}

            {facebookDomainVerification && (
                <meta name="facebook-domain-verification" content={facebookDomainVerification} />
            )}

            {!!rekTitle && (
                <meta name="rek:title" content={rekTitle} />
            )}
            {!!rekDescription && (
                <meta name="rek:description" content={rekDescription} />
            )}
            {!!rekImage && !_.isEmpty(rekImage) &&  (
                <>
                    <meta name="rek:image" content={rekImage?.src} />
                    <meta name="rek:image:alt" content={rekImage?.alt} />
                </>
            )}
            {!!rekPubdate && (
                <meta property="rek:pubdate" content={rekPubdate} />
            )}
            {!!rekModdate && (
                <meta property="rek:moddate" content={rekModdate} />
            )}

            {/* Last day this page shall be recommended */}
            {!!rekEnddate && (
                <meta property="rek:enddate" content={rekEnddate} />
            )}

            {ldScripts && ldScripts.map((content, index) => (
                <script
                    key={index}
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            ))}
        </Head>
    );
};

Meta.propTypes = {
    htmlTitle: PropTypes.string,
    canonicalLink: PropTypes.string,
    description: PropTypes.string,
    ogTitle: PropTypes.string,
    ogDescription: PropTypes.string,
    ogUrl: PropTypes.string,
    ogImage: PropTypes.string,
    ogType: PropTypes.string,
    twitterSite: PropTypes.string,
    twitterUser: PropTypes.string,
    twitterTitle: PropTypes.string,
    twitterDescription: PropTypes.string,
    twitterUrl: PropTypes.string,
    twitterImage: PropTypes.string,
    robots: PropTypes.string,
    pageId: PropTypes.number,
    translations: PropTypes.array,
    googleSiteVerification: PropTypes.string,
    facebookDomainVerification: PropTypes.string,
    rekTitle: PropTypes.string,
    rekDescription: PropTypes.string,
    rekImage: PropTypes.object,
    rekPubdate: PropTypes.string,
    rekModdate: PropTypes.string,
    rekEnddate: PropTypes.string,
    ldScripts: PropTypes.array,
};

export default BasePage;
